import initialStates from "./initial-states";
import moment from "moment";

export default {
    namespaced: true,
    state     : {
        resources: initialStates.resources.resources(),
        ceo      : initialStates.resources.ceo(),
    },
    mutations : {
        CLEAR_ALL(state){
            state.resources = initialStates.resources.resources();
            state.ceo = initialStates.resources.ceo();
        },
        setAll(state, payload){
            state.resources = [];
            for (let type in payload) {
                for (let name in payload[type]) {
                    if (!name) continue;
                    let data = payload[type][name];
                    data.name = name;
                    data.type = type;
                    data.status = processStatus(data);
                    state.resources.push(data);
                }
            }
        },
        setCeoList(state, payload){
            state.ceo = payload;
        },
        removeFromCeoList(state, {type, name}){
            state.ceo = state.ceo.filter(item => !(item.type === type && item.name === name));
        },
        deleteResource(state, {type, name}){
            let id = state.resources.findIndex(r => {
                return r.type === type && r.name === name;
            });
            state.resources.splice(id, 1);
        },
        deleteRequest(state, {type, name}){
            let id = state.resources.findIndex(r => {
                return r.type === type && r.name === name;
            });
            // if extension
            if (state.resources[id].eov) {
                let resource = state.resources.splice(id, 1)[0];
                delete resource.request;
                resource.status = processStatus(resource);
                state.resources.push(resource);
            } else {
                state.resources.splice(id, 1);
            }
        }
    },
    actions   : {
        loadAll({commit}, type){
            return new Promise((resolve, reject) => {
                let action = type ? 'list' : 'overview';
                return window.UMS.api.get('resource', action, {type})
                    .then(response => {
                        commit('setAll', response.data);
                        resolve();
                    }).catch(() => {
                        commit('setAll', []);
                        reject();
                    });
            });
        },
        loadCeoList({commit}){
            return window.UMS.api.get('resource-ceo', 'list')
                .then(response => {
                    commit('setCeoList', response.data);
                });
        },
        loadRequestForm({commit}, {type, name}){
            return window.UMS.api.get('resource', 'form', {type, name});
        },
        createRequest({commit}, data){
            return window.UMS.api.post('resource', 'create', data);
        },
        extendRequest({commit}, data){
            return window.UMS.api.post('resource', 'extend', data);
        },
        acceptRequest({commit}, {type, name}){
            return window.UMS.api.post('resource-ceo', 'accept', {type, name})
                .then(({data}) => {
                    if (data.success) {
                        commit('removeFromCeoList', {type, name});
                    }
                });
        },
        declineRequest({commit}, {type, name, reason}){
            return window.UMS.api.post('resource-ceo', 'decline', {type, name, reason})
                .then(({data}) => {
                    if (data.success) {
                        commit('removeFromCeoList', {type, name});
                    }
                });
        },
        deleteRequest({commit}, {type, name}){
            return window.UMS.api.post('resource', 'delete-request', {type, name})
                .then(({data}) => {
                    if (data.success) {
                        commit('deleteRequest', {type, name});
                    }
                });
        },
        deleteResource({commit}, {type, name, password}){
            return window.UMS.api.post('resource', 'delete', {type, name, password})
                .then(response => {
                    if (response.data.success === 1) {
                        commit('deleteResource', {type, name});
                    }
                });
        },
        // createGroupCert({commit}, {csr, functional_account}){
        //     return window.UMS.api.post('resource', 'create-group-cert', {csr, functional_account});
        // },
        createGroupCertIntern({commit}, {csr, functional_account}){
            return window.UMS.api.post('resource', 'create-group-cert-intern', {csr, functional_account});
        },
        // readGroupCerts({commit}, {functional_account}){
        //     return window.UMS.api.post('resource', 'readGroupCerts', {functional_account});
        // },
        readGroupCertsIntern({commit}, {functional_account}){
            return window.UMS.api.post('resource', 'readGroupCertsIntern', {functional_account});
        },
    },
    getters   : {
        all(state){
            return state.resources;
        },
        byType(state){
            return (type) => state.resources.filter(resource => resource.type === type);
        },
        types(state){
            let types = {};
            state.resources.forEach(resource => types[resource.type] = resource.type);

            return Object.keys(types);
        },
        requests(state, getters){
            return getters.all.filter(element => element.request).map(element => element.request);
        },
        ceo(state){
            return state.ceo;
        }
    }
};

function processStatus(resource) {
    let requestExists = !!resource.request;
    let eovDiff = moment().diff(moment(resource.eov), 'days');

    if (!requestExists && eovDiff < 0) {
        return 'ok';
    }

    if (!requestExists && eovDiff > 0) {
        return 'expired';
    }

    if (requestExists && !resource.request.confirmed) {
        return 'unconfirmed';
    }

    if (requestExists && resource.request.confirmed) {
        return 'confirmed';
    }

    return 'ok';
}